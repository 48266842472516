import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

import { useReducer } from "react";
import moment from "moment";

import { toast } from "react-toastify";
import { useParams, Link, useHistory } from "react-router-dom";

import Loader from "../loader";

import ReactModal from "react-modal";

import Select from "react-select";

import NumberFormat from "react-number-format";

import currencyFormatter from "../../helpers/currencyFormatter";

import OperatorAutocomplete from "../opi/operator-autocomplete";
import StateDisplay from "../reusable-modules/state-display";

import EmailInput from "../reusable-modules/email-input";
import TextAreaInput from "../textarea-input";
import TextInput from "../text-input";

import { Datepicker, momentTimezone } from "@mobiscroll/react";

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case "modalFieldChanged": {
      return {
        ...state,
        modalData: {
          ...state.modalData,
          [action.field]: action.value,
        },
      };
    }

    case "recipientDetailFieldChanged": {
      return {
        ...state,
        modalData: {
          ...state.modalData,
          recipientDetail: {
            ...state.modalData?.recipientDetail,
            [action.field]: action.value,
          },
        },
      };
    }

    case "paymentDetailFieldChanged": {
      return {
        ...state,
        modalData: {
          ...state.modalData,
          paymentDetail: {
            ...state.modalData?.paymentDetail,
            [action.field]: action.value,
          },
        },
      };
    }

    case "leadDesignationFieldChanged": {
      return {
        ...state,
        modalData: {
          ...state.modalData,
          leadDesignation: {
            ...state.modalData?.leadDesignation,
            [action.field]: action.value,
          },
        },
      };
    }

    case "contractOFRDetailFieldChanged": {
      return {
        ...state,
        modalData: {
          ...state.modalData,
          contractOFRDetail: {
            ...state.modalData?.contractOFRDetail,
            [action.field]: action.value,
          },
        },
      };
    }

    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }

    case "dataDownloaded": {
      return {
        ...state,
        loading: false,
        contractHTML: action?.payload?.data?.html,
        pdfData: action?.payload?.data?.pdf,
        contractRecord: action?.payload?.data?.contractRecord,
        fileName: action?.payload?.data?.fileName,
      };
    }

    case "pdfDownloaded": {
      return {
        ...state,
        pdfData: action.payload.data,
      };
    }

    default:
      break;
  }
  return state;
};

const ContractPreviewContent = (props) => {
  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();
  const { tripId, contractId } = useParams();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdmin = userRoles.some(function (role) {
    return "Super Admin" === role;
  });

  const accountDirector = userRoles.some(function (role) {
    return "Account Director" === role;
  });

  let initialState = {
    loading: true,
    modalTitle: "",
    showModal: false,
    action: null,
    selectedOFRs: [],
    logic: {
      isCCTrip: null,
      is50KTrip: null,
      is100KTrip: null,
      isHighDollarTrip: null,
      isSubjectTo50KApproval: null,
      isSubjectTo100KApproval: null,
      ofrExists: null,
      isOFA: null,
      isSent: null,
      isSigned: null,
      isSaved: null,
      isCCApproved: null,
      isHighDollarApproved: null,
      isInReview: null,
      isApproved: null,
      isSafeToSend: null,
      contractInstructions: null,
      canMarkSent: null,
    },
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const getData = async () => {
    dispatch({ type: "dataBeganDownloading" });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .get(`/api/trips/${tripId}/contracts/${contractId}/contractPreview`)
      .catch((error) => {
        setTimeout(() => {
          toast.error("Unauthorized!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }, 50);

        return console.error(error.response);
      });

    if (response) {
      console.log("CONTRACT DATA", response.data);

      dispatch({
        type: "dataDownloaded",
        payload: {
          data: response.data,
        },
      });

      handleConditionalLogic(response?.data?.contractRecord);
    }
  };

  const handleConditionalLogic = (contractData) => {
    if (!contractData || !contractData?.tripData) {
      return false;
    }

    let tripData = contractData?.tripData;

    let isCCTrip = contractData?.pricing?.paymentType === "cc";

    let is50KTrip =
      contractData?.pricing?.flightCharges >= 50000 &&
      contractData?.pricing?.flightCharges < 100000;
    let is100KTrip = contractData?.pricing?.flightCharges >= 100000;
    let isHighDollarTrip = is50KTrip || is100KTrip;

    let isHighDollarApproved = contractData.highDollarApproval;

    if (!isHighDollarTrip) {
      isHighDollarApproved = true;
    }

    console.log(
      "USER LIMIT",
      user["https://new.evojets.com/appMetadata"]?.highDollarLimit
    );

    let isSubjectTo50KApproval =
      is50KTrip &&
      !isHighDollarApproved &&
      (user["https://new.evojets.com/appMetadata"]?.highDollarLimit <= 50000 ||
        !user["https://new.evojets.com/appMetadata"]?.highDollarLimit);

    let isSubjectTo100KApproval =
      is100KTrip &&
      !isHighDollarApproved &&
      (user["https://new.evojets.com/appMetadata"]?.highDollarLimit <= 100000 ||
        !user["https://new.evojets.com/appMetadata"]?.highDollarLimit);

    if (superAdmin) {
      isSubjectTo50KApproval = false;
      if (!user["https://new.evojets.com/appMetadata"]?.highDollarLimit) {
        isSubjectTo100KApproval = false;
      }
    }

    let ofrExists = !!contractData?.operatorFinancialRecords?.length;

    let isOFA = contractData?.stage === "ofa";

    let isSent = contractData?.stage === "sent";
    let isSigned = contractData?.stage === "signed";
    let isSaved = contractData?.stage === "saved";
    let isApproved = contractData?.stage === "approved";

    let isCCApproved = contractData.ccApproval;

    let isInReview = contractData?.stage === "inReview";

    let canMarkSent = false;

    if (superAdmin) {
      canMarkSent = true;
    }

    if (accountDirector) {
      //if trip is high dollar trip, and it's under user's threshold,
      //they can mark it sent

      if (isHighDollarTrip) {
        if (
          user["https://new.evojets.com/appMetadata"]?.highDollarLimit <=
          contractData?.pricing?.flightCharges
        ) {
          canMarkSent = true;
        }
      } else {
        canMarkSent = true;
      }
    }

    //isSafeToSend is true if the contract is not yet sent or signed, and if it's a high dollar trip, it's been approved, and if it's a CC trip, it's been CC approved
    let isSafeToSend = !isSent && !isSigned;

    if (isHighDollarTrip) {
      isSafeToSend = isSafeToSend && isHighDollarApproved;
    }
    if (isCCTrip) {
      isSafeToSend = isSafeToSend && isCCApproved;
    }

    if (!contractData?.operatorFinancialRecords?.length) {
      isSafeToSend = false;
    }

    let requiresCCApproval = isCCTrip && !isCCApproved;
    let requiresDollarApproval = isHighDollarTrip && !isHighDollarApproved;

    let contractInstructions = ``;

    if (requiresCCApproval || requiresDollarApproval || !ofrExists) {
      contractInstructions = (
        <React.Fragment>
          <p>
            <strong>This trip requires the following:</strong>
          </p>
          <ul>
            {!ofrExists && <li>At least one OFR</li>}
            {requiresCCApproval && <li>CC Approval</li>}
            {requiresDollarApproval && <li>High Dollar Approval</li>}
          </ul>
        </React.Fragment>
      );
    }

    if (contractData?.associatedOFRIds?.length) {
      //get matching OFRs from contractData?.operatorFinancialRecords
      let matchingOFRs = contractData?.operatorFinancialRecords.filter(
        (ofr) => {
          return contractData?.associatedOFRIds.includes(ofr?._id);
        }
      );

      if (matchingOFRs?.length) {
        let matchingOFROptions = matchingOFRs.map((ofr) => {
          return {
            label: `#${ofr?.ofrTag} | ${ofr?.operator?.name} | ${
              ofr?.operatorPrice
                ? currencyFormatter.format(ofr?.operatorPrice)
                : "No Operator Price"
            }`,
            value: ofr?._id,
          };
        });

        dispatch({
          type: "fieldChanged",
          field: "selectedOFRs",
          value: matchingOFROptions,
        });
      }
    }

    dispatch({
      type: "fieldChanged",
      field: "logic",
      value: {
        isCCTrip,
        is50KTrip,
        is100KTrip,
        isHighDollarTrip,
        isSubjectTo100KApproval,
        isSubjectTo50KApproval,
        ofrExists,
        isOFA,
        isSent,
        isSigned,
        isSaved,
        isCCApproved,
        isInReview,
        isApproved,
        isSafeToSend,
        contractInstructions,
        isHighDollarApproved,
        requiresCCApproval,
        canMarkSent,
      },
    });
  };

  const handlePDF = async () => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .get(`/api/trips/${tripId}/contracts/${contractId}/pdf`, {
        responseType: "arraybuffer",
      })
      .catch((error) => {
        setTimeout(() => {
          toast.error("Unauthorized!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }, 50);

        return console.error(error.response);
      });

    if (response) {
      console.log(response.data);

      dispatch({
        type: "pdfDownloaded",
        payload: {
          data: response.data,
        },
      });

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );

      let link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${state.fileName ? state.fileName : `evo-contract.pdf`}`
      );
      document.body.appendChild(link);
      link.click();
    }
  };

  const handleAssociatedOFRChange = () => {
    let selectedOFRs = state.selectedOFRs.map((ofr) => {
      return state.contractRecord?.operatorFinancialRecords.find((record) => {
        return record?._id === ofr?.value;
      });
    });

    console.log("SELECTED OFRS", selectedOFRs);

    //for each OFR, get the following
    // operator name
    //dusCancellationPolicy

    let operatorBreakdown = selectedOFRs.flatMap((ofr) => {
      if (!ofr) {
        return [];
      }

      let dusCancellationPolicy = ``;

      if (ofr?.dusCancellationPolicy?.value === 0) {
        dusCancellationPolicy = `No Fee`;
      } else {
        let type =
          ofr?.dusCancellationPolicy?.type === 1
            ? "%"
            : ofr?.dusCancellationPolicy?.type === 2
            ? "$"
            : false;
        if (type === "%") {
          dusCancellationPolicy = `${ofr?.dusCancellationPolicy?.value}${type}`;
        } else if (type === "$") {
          dusCancellationPolicy = `${type}${ofr?.dusCancellationPolicy?.value}`;
        }
      }

      let remainingPenalties = ofr?.operatorCancellationPolicies.flatMap(
        (policy) => {
          if (policy?.dueDate && policy.value !== 0 && policy.type) {
            let type =
              policy?.type === 1 ? "%" : policy?.type === 2 ? "$" : false;

            let formattedDate = moment(policy?.dueDate).format("MM/DD/YYYY");

            if (type === "%") {
              return `${formattedDate} - ${policy?.value}${type}`;
            }

            if (type === "$") {
              let formattedValue = currencyFormatter.format(policy?.value);
              return `${formattedDate} - ${formattedValue}`;
            }
          }

          return [];
        }
      );

      return {
        operatorName: ofr?.operator?.name,
        dusCancellationPolicy: dusCancellationPolicy,
        remainingPenalties: remainingPenalties,
      };
    });

    //update paymentDetail.operatorPrices

    let operatorPrices = selectedOFRs.map((ofr) => {
      return {
        operatorName: ofr?.operator?.name,
        operatorPrice: ofr?.operatorPrice,
      };
    });

    dispatch({
      type: "fieldChanged",
      field: "modalData",
      value: {
        ...state.modalData,
        contractOFRDetail: {
          ...state.modalData?.contractOFRDetail,
          operatorBreakdown,
        },
        paymentDetail: {
          ...state.modalData?.paymentDetail,
          operatorPrices,
        },
      },
    });
  };

  useEffect(() => {
    handleAssociatedOFRChange();
  }, [state.selectedOFRs]);

  const handleContractModal = (e) => {
    let action = e.target.name;

    let actionsRequiringModal = [
      "requestToSend",
      "requestCC",
      "requestDollarApproval50k",
      "requestDollarApproval100k",
      "markSent",
      "markSigned",
      "archiveEvoContract",
      "revokeSendRequest",
      "highDollarApproval50k",
      "highDollarApproval100k",
    ];

    if (!actionsRequiringModal.includes(action)) {
      return false;
    }

    dispatch({
      type: "fieldChanged",
      field: "modalButtonText",
      value: "Submit",
    });

    let legDates = ``;
    let routingString = ``;

    //get leg one departure date
    //get last leg departure date IF there is more than one leg
    // format as MM/DD/YYYY - MM/DD/YYYY (or just MM/DD/YYYY if there's only one leg)

    if (state.contractRecord?.legs?.length) {
      let firstLegDepartureDate = moment(
        state.contractRecord?.legs[0]?.departureDate
      ).format("MM/DD/YYYY");

      let lastLegDepartureDate = moment(
        state.contractRecord?.legs[state.contractRecord?.legs?.length - 1]
          ?.departureDate
      ).format("MM/DD/YYYY");

      if (state.contractRecord?.legs?.length === 1) {
        legDates = firstLegDepartureDate;
      } else {
        legDates = `${firstLegDepartureDate} - ${lastLegDepartureDate}`;
      }

      //get departure airport code for each leg, stitched together by -

      let departureAirportCodes = state.contractRecord?.legs.map((leg) => {
        return leg?.departureAirport?.code;
      });

      if (state.contractRecord?.legs?.length > 3) {
        //first departure airport code
        routingString = `${departureAirportCodes[0]}-Multi`;
      }

      if (
        state.contractRecord?.legs?.length <= 3 &&
        state.contractRecord?.legs?.length > 1
      ) {
        //push last arrival airport code to departureAirportCodes
        departureAirportCodes.push(
          state.contractRecord?.legs[state.contractRecord?.legs?.length - 1]
            ?.arrivalAirport?.code
        );

        routingString = departureAirportCodes.join("-");
      }

      if (state.contractRecord?.legs?.length === 1) {
        routingString = `${departureAirportCodes[0]} - ${state.contractRecord?.legs[0]?.arrivalAirport?.code}`;
      }
    }

    //get OFRs from state.contractRecord?.operatorFinancialRecords using state.selectedOFRs

    let selectedOFRs = state.selectedOFRs.map((ofr) => {
      return state.contractRecord?.operatorFinancialRecords.find((record) => {
        return record?._id === ofr?.value;
      });
    });

    console.log("SELECTED OFRS", selectedOFRs);

    //for each OFR, get the following
    // operator name
    //dusCancellationPolicy

    let operatorBreakdown = selectedOFRs.flatMap((ofr) => {
      if (!ofr) {
        return [];
      }

      let dusCancellationPolicy = ``;

      if (ofr?.dusCancellationPolicy?.value === 0) {
        dusCancellationPolicy = `No Fee`;
      } else {
        let type =
          ofr?.dusCancellationPolicy?.type === 1
            ? "%"
            : ofr?.dusCancellationPolicy?.type === 2
            ? "$"
            : false;
        if (type === "%") {
          dusCancellationPolicy = `${ofr?.dusCancellationPolicy?.value}${type}`;
        } else if (type === "$") {
          dusCancellationPolicy = `${type}${ofr?.dusCancellationPolicy?.value}`;
        }
      }

      let remainingPenalties = ofr?.operatorCancellationPolicies.flatMap(
        (policy) => {
          if (policy?.dueDate && policy.value !== 0 && policy.type) {
            let type =
              policy?.type === 1 ? "%" : policy?.type === 2 ? "$" : false;

            let formattedDate = moment(policy?.dueDate).format("MM/DD/YYYY");

            if (type === "%") {
              return `${formattedDate} - ${policy?.value}${type}`;
            }

            if (type === "$") {
              let formattedValue = currencyFormatter.format(policy?.value);
              return `${formattedDate} - ${formattedValue}`;
            }
          }

          return [];
        }
      );

      return {
        operatorName: ofr?.operator?.name,
        dusCancellationPolicy: dusCancellationPolicy,
        remainingPenalties: remainingPenalties,
      };
    });

    let recipientName = `${state?.contractRecord?.clientData?.firstName} ${state?.contractRecord?.clientData?.lastName}`;
    let signerName = state?.contractRecord?.signerName
      ? `${state?.contractRecord?.signerName}`
      : false;
    let onBehalfOf = state?.contractRecord?.companyName
      ? `${state?.contractRecord?.companyName}`
      : false;

    let signerEmail = state?.contractRecord?.signerEmail || false;
    let totalClientPayment = currencyFormatter.format(
      state.contractRecord?.pricing?.flightCharges
    );

    let subtotal = currencyFormatter.format(
      state.contractRecord?.pricing?.flightCharges
    );

    //if ccFee is number and if state.contractRecord?.pricing?.paymentType is "cc"
    // add ccFee to  state.contractRecord?.pricing?.flightCharges, replace totalClientPayment

    if (
      state.contractRecord?.pricing?.ccFee &&
      state.contractRecord?.pricing?.paymentType === "cc" &&
      !isNaN(parseFloat(state.contractRecord?.pricing?.ccFee))
    ) {
      totalClientPayment = currencyFormatter.format(
        parseFloat(state.contractRecord?.pricing?.flightCharges) +
          parseFloat(state.contractRecord?.pricing?.ccFee)
      );
    }

    //set modalData
    dispatch({
      type: "fieldChanged",
      field: "modalData",
      value: {
        ...state.modalData,
        clientPricePreFees: currencyFormatter.format(
          state.contractRecord?.pricing?.flightCharges -
            state.contractRecord?.pricing?.ccFee
        ),
        ccFee: currencyFormatter.format(state.contractRecord?.pricing?.ccFee),
        operatorName: state.contractRecord?.legs[0]?.operator?.name,
        operatorPrice: "",
        totalClientPayment: currencyFormatter.format(
          state.contractRecord?.pricing?.flightCharges
        ),
        recipientDetail: {
          fullName: recipientName,
          email: state?.contractRecord?.clientData?.emails[0] || "",
          ccEmails: [],
          companyName: state?.contractRecord?.companyName || "",
          sendingNotes: "",
          signerName,
          onBehalfOf,
          signerEmail,
          recipientName: signerName || recipientName,
          recipientEmail:
            signerEmail || state?.contractRecord?.clientData?.emails[0] || "",
        },
        leadDesignation: {
          clientType: null,
          leadSource: null,
          firstContact: "",
          leadNotes: "",
        },
        contractOFRDetail: {
          clientName: `${state?.contractRecord?.clientData?.firstName} ${state?.contractRecord?.clientData?.lastName}`,
          dates: legDates,
          routing: routingString,
          operatorBreakdown,
          contractNotes: "",
        },
        paymentDetail: {
          paymentType: state.contractRecord?.pricing?.paymentType,
          paymentNotes: "",
          totalClientPayment: totalClientPayment,
          clientPricePreFees: currencyFormatter.format(
            state.contractRecord?.pricing?.flightCharges -
              state.contractRecord?.pricing?.ccFee
          ),
          ccFee: currencyFormatter.format(state.contractRecord?.pricing?.ccFee),
          operatorPrices: selectedOFRs.map((ofr) => {
            return {
              operatorName: ofr?.operator?.name,
              operatorPrice: ofr?.operatorPrice,
            };
          }),
          payReferralFeeTo: "",
          referralFee: "",
          subtotal,
        },
      },
    });

    console.log("MODAL DATA - OPERATOR BREAKDOWN", operatorBreakdown);

    if (action === "requestToSend") {
      //show requestToSend

      dispatch({
        type: "fieldChanged",
        field: "modalTitle",
        value: "Request To Send",
      });

      dispatch({
        type: "fieldChanged",
        field: "action",
        value: action,
      });
    }

    if (action === "requestCC") {
      //show cc modal

      dispatch({
        type: "fieldChanged",
        field: "modalTitle",
        value: "Request CC Approval",
      });

      dispatch({
        type: "fieldChanged",
        field: "action",
        value: action,
      });
    }

    if (
      action === "requestDollarApproval50k" ||
      action === "requestDollarApproval100k"
    ) {
      //show dollar approval

      dispatch({
        type: "fieldChanged",
        field: "modalTitle",
        value: "Request High Dollar Approval",
      });

      dispatch({
        type: "fieldChanged",
        field: "action",
        value: action,
      });
    }

    if (
      action === "highDollarApproval50k" ||
      action === "highDollarApproval100k"
    ) {
      //show dollar approval

      dispatch({
        type: "fieldChanged",
        field: "modalTitle",
        value: "Approve High Dollar Contract",
      });

      dispatch({
        type: "fieldChanged",
        field: "action",
        value: action,
      });

      dispatch({
        type: "fieldChanged",
        field: "modalButtonText",
        value: "Approve",
      });
    }

    if (action === "markSent") {
      dispatch({
        type: "fieldChanged",
        field: "modalTitle",
        value: "Mark Contract Sent",
      });

      dispatch({
        type: "fieldChanged",
        field: "action",
        value: action,
      });

      dispatch({
        type: "fieldChanged",
        field: "modalButtonText",
        value: "Mark Sent",
      });
    }

    if (action === "markSigned") {
      dispatch({
        type: "fieldChanged",
        field: "modalTitle",
        value: "Mark Contract Signed",
      });

      dispatch({
        type: "fieldChanged",
        field: "action",
        value: action,
      });

      dispatch({
        type: "fieldChanged",
        field: "modalButtonText",
        value: "Mark Signed",
      });
    }

    if (action === "revokeSendRequest") {
      dispatch({
        type: "fieldChanged",
        field: "modalTitle",
        value: "Revoke Send Request",
      });

      dispatch({
        type: "fieldChanged",
        field: "action",
        value: action,
      });

      dispatch({
        type: "fieldChanged",
        field: "modalButtonText",
        value: "Revoke",
      });
    }

    dispatch({
      type: "fieldChanged",
      field: "showModal",
      value: true,
    });

    return true;
  };

  const handleContractActionButton = async (e) => {
    e.preventDefault();

    dispatch({
      type: "fieldChanged",
      field: "action",
      value: e.target.name,
    });

    let modalResponse = handleContractModal(e);

    if (modalResponse) {
      return;
    }

    const response = await handleActionSubmit(e.target.name);

    return response;
  };

  useEffect(() => {
    getData();
    document.title = "Contract Preview | Flight Deck by evoJets";
  }, []);

  const handleModalSelectChange = (selectedValue, action) => {
    console.log({ selectedValue, action });

    dispatch({
      type: "modalFieldChanged",
      field: action.name,
      value: selectedValue.value,
    });
  };

  const handleModalPriceFieldChange = (values, sourceInfo) => {
    const { formattedValue, value } = values;
    const { event, source } = sourceInfo;

    if (!event?.target) {
      return;
    }

    dispatch({
      type: "modalFieldChanged",
      field: event?.target?.name,
      value: formattedValue,
    });
  };

  const handleModalFormSubmission = async (e) => {
    e.preventDefault();

    await handleActionSubmit(state.action);

    return true;
  };

  const resetSelection = (fieldName) => {
    setTimeout(() => {
      dispatch({
        type: "modalFieldChanged",
        field: fieldName,
        value: null,
      });
    }, 0);
  };

  const handleOperatorChange = (selectedOperator) => {
    console.log(selectedOperator);

    if (selectedOperator) {
      if (selectedOperator?.restrictions?.length) {
        let confirm = window.confirm(
          `This operator has a status of: ${selectedOperator?.restrictions.join(
            ", "
          )}. Do you wish to proceed?`
        );

        if (!confirm) {
          return resetSelection("selectedOperator");
        }
      }

      dispatch({
        type: "modalFieldChanged",
        field: "selectedOperator",
        value: selectedOperator,
      });
    } else {
      resetSelection("selectedOperator");
    }
  };

  const handleActionSubmit = async (action) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    console.log({
      action,
      actionData: state.modalData,
    });

    // return;

    let response = await ai
      .auth(token)
      .put(`/api/trips/${tripId}/contracts/${contractId}/action`, {
        action: action,
        actionData: state.modalData,
      })
      .catch((error) => {
        setTimeout(() => {
          toast.error("Unauthorized!", toastOptions);
        }, 50);

        return console.error(error.response);
      });

    if (response.data && response?.data?.message) {
      toast.success(response?.data?.message, toastOptions);
      //close modal

      dispatch({
        type: "fieldChanged",
        field: "showModal",
        value: false,
      });
    }

    console.log(response.data);
  };

  const {
    isCCTrip,
    is50KTrip,
    is100KTrip,
    isHighDollarTrip,
    ofrExists,
    isOFA,
    isSent,
    isSigned,
    isSaved,
    isCCApproved,
    isInReview,
    isApproved,
    isSafeToSend,
    contractInstructions,
    isHighDollarApproved,
    requiresCCApproval,
    isSubjectTo100KApproval,
    isSubjectTo50KApproval,
    canMarkSent,
  } = state.logic;

  console.log("LOGIC", state.logic);

  console.log(
    "HIGH DOLLAR PARAMS",

    {
      superadmin: superAdmin,
      isNotSubjectTo100k: !isSubjectTo100KApproval,
      is100k: is100KTrip,
      isNotHighDollarApproved: !isHighDollarApproved,
      isSubjectTo100k: isSubjectTo100KApproval,
    }
  );

  return (
    <React.Fragment>
      <h2 id="addAirportHeading">Preview Contract</h2>

      {state.loading && <Loader></Loader>}

      {!state.loading && (
        <React.Fragment>
          <h4>Shortcuts</h4>

          <div className="d-flex flex-wrap flex-align-center column-gap-20">
            <Link to={`/trips/${tripId}`} className={`base-button evo-blue`}>
              Return to Trip
            </Link>

            {!isSigned && (
              <Link
                to={`/trips/${tripId}/contracts/${contractId}`}
                className={`base-button evo-blue`}
              >
                Edit Contract
              </Link>
            )}

            {!ofrExists && (
              <Link
                to={`/trips/${tripId}/financials/new`}
                className={`base-button evo-blue`}
              >
                Create OFR
              </Link>
            )}
          </div>

          <hr />

          <h4>Contract Requests</h4>

          {contractInstructions && contractInstructions}

          <div className="d-flex flex-wrap flex-align-center gap-20">
            {isSafeToSend && (
              <button
                className="base-button evo-dark-green "
                onClick={handleContractActionButton}
                name="requestToSend"
              >
                Request to Send
              </button>
            )}

            {isSubjectTo50KApproval && ofrExists && (
              <button
                className="base-button evo-dark-green "
                onClick={handleContractActionButton}
                name="requestDollarApproval50k"
              >
                Request Dollar Approval
              </button>
            )}

            {isSubjectTo100KApproval && ofrExists && (
              <button
                className="base-button evo-dark-green "
                onClick={handleContractActionButton}
                name="requestDollarApproval100k"
              >
                Request Dollar Approval
              </button>
            )}
            {isCCTrip && !isCCApproved && (
              <button
                className="base-button evo-dark-green "
                onClick={handleContractActionButton}
                name="requestCC"
              >
                Request CC Approval
              </button>
            )}
            {ofrExists && (
              <button className="evo-blue base-button " onClick={handlePDF}>
                Save as PDF
              </button>
            )}
          </div>

          <hr />

          <h4>Contract Stage Changes</h4>

          <div className="d-flex flex-wrap flex-align-center gap-20">
            {/* {isOFA && (
              <p className="mb-0">
                <strong>
                  Your contract is out for approval. Once approved, additional
                  options will become available.
                </strong>
              </p>
            )}

            {isHighDollarApproved && isHighDollarTrip && !ofrExists && (
              <p className="mb-0">
                <strong>
                  {`Your high dollar contract is approved, but still requires an OFR${
                    isCCTrip && !isCCApproved && ` and CC approval`
                  }. Please
                  create an OFR and request CC approval to take further action.`}
                </strong>
              </p>
            )}

            {!isHighDollarApproved && isHighDollarTrip && !isOFA && (
              <p className="mb-0">
                <strong>
                  {`Your high dollar contract requires approval. Please request approval to take further action.`}
                </strong>
              </p>
            )}

            {requiresCCApproval && (
              <p className="mb-0">
                <strong>
                  {`Your contract requires CC approval. Please request approval to take further action.`}
                </strong>
              </p>
            )} */}

            {isHighDollarApproved &&
              ofrExists &&
              canMarkSent &&
              !requiresCCApproval &&
              !isSent && (
                <button
                  className="base-button evo-dark-green "
                  onClick={handleContractActionButton}
                  name="markSent"
                >
                  Mark Sent
                </button>
              )}

            {isSent && (
              <button
                className="base-button evo-dark-green "
                onClick={handleContractActionButton}
                name="markSigned"
              >
                Mark Signed
              </button>
            )}

            {isSigned && (
              <React.Fragment>
                <button
                  className="base-button evo-dark-green "
                  onClick={handleContractActionButton}
                  name="markUnsigned"
                >
                  Mark Unsigned
                </button>
                <button
                  className="base-button evo-dark-green "
                  onClick={handleContractActionButton}
                  name="cancelEvoContract"
                >
                  Cancel Evo Contract
                </button>
              </React.Fragment>
            )}

            {isSaved && (
              <button
                className="base-button evo-dark-green "
                onClick={handleContractActionButton}
                name="archiveEvoContract"
              >
                Archive Evo Contract
              </button>
            )}

            {isInReview && (
              <button
                className="base-button evo-dark-green "
                onClick={handleContractActionButton}
                name="revokeSendRequest"
              >
                Revoke Send Request
              </button>
            )}
          </div>

          {((isCCTrip && !isCCApproved) ||
            (isHighDollarTrip && !isHighDollarApproved)) &&
            superAdmin && (
              <React.Fragment>
                <hr></hr>

                <h4>Contract Approvals</h4>

                <div className="d-flex flex-wrap flex-align-center gap-20">
                  {isCCTrip && !isCCApproved && superAdmin && (
                    <button
                      className="base-button evo-dark-green "
                      onClick={handleContractActionButton}
                      name="ccApproval"
                    >
                      Approve CC
                    </button>
                  )}

                  {is50KTrip && superAdmin && !isHighDollarApproved && (
                    <button
                      className="base-button evo-dark-green "
                      onClick={handleContractActionButton}
                      name={"highDollarApproval50k"}
                    >
                      Approve Dollar Amount
                    </button>
                  )}

                  {superAdmin &&
                    !isSubjectTo100KApproval &&
                    is100KTrip &&
                    !isHighDollarApproved && (
                      <button
                        className="base-button evo-dark-green "
                        onClick={handleContractActionButton}
                        name={"highDollarApproval100k"}
                      >
                        Approve Dollar Amount
                      </button>
                    )}
                </div>
              </React.Fragment>
            )}

          <hr></hr>
        </React.Fragment>
      )}

      <ReactModal
        isOpen={state.showModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          dispatch({
            type: "fieldChanged",
            field: "showModal",
            value: false,
          });
        }}
        style={{
          overlay: {
            zIndex: 999999,
            backgroundColor: "rgb(32 53 68 / 50%)",
          },
          content: {
            width: "auto",
            // height: "700px",
            // padding: "40px",
            border: "none",
            maxWidth: "700px",
            maxHeight: "90vh",
            minWidth: "50vw",
            borderRadius: "0",
            boxShadow: "10px 10px 91px -17px rgb(0 0 0 / 25%)",
          },
        }}
        appElement={document.getElementById("app")}
      >
        <div id="modal-container">
          <div className="modal-header edit-note-modal">
            <h4>{state.modalTitle}</h4>
          </div>
          <div className="modal-body edit-note-modal">
            <form
              onSubmit={handleModalFormSubmission}
              className="w-100 base-form mt-20 tight-paragraphs pb-20"
            >
              {[
                "requestToSend",
                "requestCC",
                "requestDollarApproval100k",
                "requestDollarApproval50k",
              ].includes(state.action) &&
                state.contractRecord?.operatorFinancialRecords?.length && (
                  <React.Fragment>
                    <h5>Associated OFR(s)</h5>

                    <div className="form-group">
                      <Select
                        className="base-select yom-select"
                        isClearable
                        isSearchable
                        placeholder={"Select All Applicable OFRs"}
                        name="associatedOFRs"
                        onChange={(selectedValue, action) => {
                          console.log(selectedValue);
                          let ids = [];

                          if (selectedValue?.length) {
                            ids = selectedValue.map((value) => {
                              return value.value;
                            });
                          }
                          dispatch({
                            type: "modalFieldChanged",
                            field: "associatedOFRIds",
                            value: ids,
                          });

                          dispatch({
                            type: "fieldChanged",
                            field: "selectedOFRs",
                            value: selectedValue,
                          });
                        }}
                        isMulti
                        options={state.contractRecord?.operatorFinancialRecords.map(
                          (ofr) => {
                            return {
                              label: `#${ofr?.ofrTag} | ${
                                ofr?.operator?.name
                              } | ${
                                ofr?.operatorPrice
                                  ? currencyFormatter.format(ofr?.operatorPrice)
                                  : "No Operator Price"
                              }`,
                              value: ofr?._id,
                            };
                          }
                        )}
                        value={state.selectedOFRs}
                      ></Select>
                    </div>
                  </React.Fragment>
                )}

              {state.action === "requestToSend" && (
                <React.Fragment>
                  <h5>RECIPIENT DETAIL</h5>
                  {/* <p>
                    <strong>Client Name: </strong>
                    {state.modalData?.recipientDetail?.fullName}
                  </p>

                  <p>
                    <strong>Client Email: </strong>
                    {state.modalData?.recipientDetail?.email}
                  </p> */}

                  <p>
                    <strong>Recipient Name: </strong>
                    {state.modalData?.recipientDetail?.recipientName}
                  </p>
                  {state.modalData?.recipientDetail?.onBehalfOf && (
                    <p>
                      <strong>On behalf of: </strong>
                      {state.modalData?.recipientDetail?.onBehalfOf}
                    </p>
                  )}

                  <p>
                    <strong>Recipient Email: </strong>
                    {state.modalData?.recipientDetail?.recipientEmail}
                  </p>

                  <EmailInput
                    parentDispatch={dispatch}
                    dispatchType={"recipientDetailFieldChanged"}
                    dispatchField={"ccEmails"}
                    placeholder="Enter CC Email(s)"
                    label="CC Email(s)"
                    name="ccEmails"
                  ></EmailInput>
                  <TextAreaInput
                    handleChange={(e) => {
                      dispatch({
                        type: "recipientDetailFieldChanged",
                        field: "sendingNotes",
                        value: e.target.value,
                      });
                    }}
                    value={
                      state?.modalData?.recipientDetail?.sendingNotes || ""
                    }
                    label="Sending Notes"
                  ></TextAreaInput>
                  <h5>LEAD DESIGNATION</h5>
                  <div className="form-group">
                    <label className="base-label">
                      Client Type <span className="required">*</span>
                    </label>
                    <Select
                      className="clientType-select"
                      classNamePrefix="select"
                      options={[
                        {
                          label: "New",
                          value: "New",
                        },
                        {
                          label: "Repeat",
                          value: "Repeat",
                        },
                      ]}
                      placeholder="Client Type"
                      onChange={(selectedValue) => {
                        dispatch({
                          type: "leadDesignationFieldChanged",
                          field: "clientType",
                          value: selectedValue.value,
                        });
                      }}
                      key={`key-client-type`}
                      name="clientType"
                    ></Select>

                    <h5 className="mt-20">Contract / OFR Detail</h5>

                    <p>
                      <strong>Client Name: </strong>
                      {state.modalData?.contractOFRDetail?.clientName}
                    </p>

                    <p>
                      <strong>Dates: </strong>
                      {state.modalData?.contractOFRDetail?.dates}
                    </p>

                    <p>
                      <strong>Routing: </strong>
                      {state.modalData?.contractOFRDetail?.routing}
                    </p>

                    {state.modalData?.contractOFRDetail?.operatorBreakdown?.map(
                      (penalty, index) => {
                        return (
                          <p key={index}>
                            <strong>Operator {index + 1} Name:</strong>{" "}
                            {penalty?.operatorName} <br />
                            <strong>Fee Upon Booking:</strong>{" "}
                            {penalty?.dusCancellationPolicy} <br />
                            <strong>Next Penalty:</strong>{" "}
                            {penalty?.remainingPenalties?.length
                              ? penalty?.remainingPenalties.join(", ")
                              : "No Remaining Penalties"}
                          </p>
                        );
                      }
                    )}

                    <TextAreaInput
                      handleChange={(e) => {
                        dispatch({
                          type: "contractOFRDetailFieldChanged",
                          field: "contractNotes",
                          value: e.target.value,
                        });
                      }}
                      value={
                        state?.modalData?.contractOFRDetail?.contractNotes || ""
                      }
                      label="Contract Notes"
                    ></TextAreaInput>
                  </div>
                  {state.modalData?.leadDesignation?.clientType === "New" && (
                    <React.Fragment>
                      <div className="form-group">
                        <label className="base-label">
                          Lead Source <span className="required">*</span>
                        </label>
                        <Select
                          className="clientType-select"
                          classNamePrefix="select"
                          options={[
                            {
                              label: "RFP",
                              value: "RFP",
                            },
                            {
                              label: "Phone Call",
                              value: "Phone Call",
                            },
                            {
                              label: "Outreach Sequence",
                              value: "Outreach Sequence",
                            },
                            {
                              label: "UpLead",
                              value: "UpLead",
                            },
                            {
                              label: "Email In",
                              value: "Email In",
                            },
                            {
                              label: "Personal Network",
                              value: "Personal Network",
                            },
                            {
                              label: "Other",
                              value: "Other",
                            },
                          ]}
                          placeholder="Lead Source"
                          onChange={(selectedValue) => {
                            dispatch({
                              type: "leadDesignationFieldChanged",
                              field: "leadSource",
                              value: selectedValue.value,
                            });
                          }}
                          key={`key-client-type`}
                          name="clientType"
                        ></Select>
                      </div>
                      <div className="datepickerContainer mb-0">
                        <label
                          htmlFor={`firstContactDate`}
                          className="base-label"
                        >
                          First Contact <span className="required">*</span>
                        </label>

                        <Datepicker
                          value={
                            state?.modalData?.leadDesignation?.firstContact
                          }
                          onChange={(event) => {
                            dispatch({
                              type: "leadDesignationFieldChanged",
                              field: "firstContact",
                              value: event.value,
                            });
                          }}
                          dataTimezone="utc"
                          displayTimezone="local"
                          timezonePlugin={momentTimezone}
                          controls={["date"]}
                        />
                      </div>
                    </React.Fragment>
                  )}
                  <h5 className="mt-20">Payment Detail</h5>
                  <p>
                    <strong>Payment Type: </strong>
                    {state.modalData?.paymentDetail?.paymentType === "cc"
                      ? "CC"
                      : state.modalData?.paymentDetail?.paymentType === "wire"
                      ? "Wire"
                      : "-"}
                  </p>
                  <p>
                    <strong>Total Client Payment: </strong>
                    {state.modalData?.paymentDetail?.totalClientPayment}
                  </p>
                  {state.modalData?.paymentDetail?.ccFee &&
                    state.modalData?.paymentDetail?.paymentType === "cc" && (
                      <p>
                        <strong>CC Fees:</strong>{" "}
                        {state.modalData?.paymentDetail?.ccFee}
                      </p>
                    )}
                  {state.modalData?.paymentDetail?.operatorPrices?.map(
                    (price, index) => {
                      return (
                        <p key={index}>
                          <strong>Operator {index + 1}:</strong>{" "}
                          {price?.operatorName} -{" "}
                          {currencyFormatter.format(price?.operatorPrice)}
                        </p>
                      );
                    }
                  )}
                  {/* text input for pay referral fee to */}
                  <TextInput
                    handleChange={(e) => {
                      dispatch({
                        type: "paymentDetailFieldChanged",
                        field: "payReferralFeeTo",
                        value: e.target.value,
                      });
                    }}
                    value={
                      state?.modalData?.paymentDetail?.payReferralFeeTo || ""
                    }
                    label="Pay Referral Fee To"
                  ></TextInput>
                  <div className="form-group">
                    <label>Referral Fee</label>

                    <NumberFormat
                      value={state?.modalData?.paymentDetail?.referralFee || ""}
                      thousandSeparator={true}
                      prefix={"$"}
                      onValueChange={(values, sourceInfo) => {
                        const { value } = values;

                        dispatch({
                          type: "paymentDetailFieldChanged",
                          field: "referralFee",
                          value: value,
                        });
                      }}
                      placeholder="Referral Fee"
                      className="semibold-text"
                      decimalScale={2}
                      name="referralFee"
                    />
                  </div>
                  <TextAreaInput
                    handleChange={(e) => {
                      dispatch({
                        type: "paymentDetailFieldChanged",
                        field: "paymentNotes",
                        value: e.target.value,
                      });
                    }}
                    value={state?.modalData?.paymentDetail?.paymentNotes || ""}
                    label="Payment Notes"
                  ></TextAreaInput>
                </React.Fragment>
              )}

              {state.action !== "requestToSend" && (
                <React.Fragment>
                  <h5>TRIP DATA</h5>

                  <p>
                    <strong>Total Client Payment: </strong>
                    {state.modalData?.totalClientPayment}
                  </p>
                </React.Fragment>
              )}
              {isCCTrip && state.action !== "requestToSend" && (
                <div>
                  <p>
                    <strong>Subtotal (Pre-CC Fees):</strong>{" "}
                    {state.modalData?.subtotal}
                  </p>
                  <p>
                    <strong>CC Fees:</strong> {state.modalData?.ccFee}
                  </p>
                  <p>
                    <strong>Total:</strong>{" "}
                    {state.modalData?.totalClientPayment}
                  </p>
                </div>
              )}
              {state.action === "requestCC" && (
                <React.Fragment>
                  <div className="form-group ml-0 mt-20">
                    <label className="base-label">Client Type</label>
                    <Select
                      className="clientType-select"
                      classNamePrefix="select"
                      options={[
                        {
                          label: "New",
                          value: "New",
                        },
                        {
                          label: "Repeat",
                          value: "Repeat",
                        },
                      ]}
                      placeholder="Client Type"
                      onChange={handleModalSelectChange}
                      // value={state.modalData?.clientType || ""}
                      key={`key-client-type`}
                      name="clientType"
                    ></Select>
                  </div>
                </React.Fragment>
              )}

              {(state.action === "requestDollarApproval50k" ||
                state.action === "requestDollarApproval100k") && (
                <div className="form-group">
                  <label className="base-label" htmlFor="note">
                    Request Notes
                  </label>
                  <textarea
                    name="notes"
                    id="notes"
                    cols="30"
                    rows="3"
                    value={state?.modalData?.notes || ""}
                    onChange={(e) => {
                      dispatch({
                        type: "fieldChanged",
                        field: "modalData",
                        value: {
                          ...state.modalData,
                          notes: e.target.value,
                        },
                      });
                    }}
                  ></textarea>
                </div>
              )}

              <button
                className="base-button evo-blue w-100 mt-20"
                type="submit"
              >
                {state.modalButtonText ? state.modalButtonText : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </ReactModal>

      <iframe
        className="contract-preview-container"
        id="contractPreviewIframe"
        sandbox="allow-same-origin"
        srcDoc={state.contractHTML}
      ></iframe>

      <StateDisplay data={state} user={user}></StateDisplay>
    </React.Fragment>
  );
};

export default ContractPreviewContent;
