import React, { Fragment, useReducer, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ai from "../../helpers/axios";

import { useParams, Link, useHistory } from "react-router-dom";
import PageHeaderRow from "../shared/page-header-row";
import moment from "moment-timezone";

import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";

import ItineraryTable from "./itinerary-table";

import TripNotesModule from "./trip-notes-module";
import GroundTransportationModule from "./ground-transportation-module";
import CateringModule from "./catering-module";

import DynamicAccordionTable from "../reusable-modules/dynamic-accordion-table";
import StateDisplay from "../reusable-modules/state-display";

import "react-json-pretty/themes/monikai.css";
import JSONPretty from "react-json-pretty";
import Loader from "../loader";

import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IconButton from "@mui/material/IconButton";

import ReactModal from "react-modal";
import currencyFormatter from "../../helpers/currencyFormatter";
import Select from "react-select";

// mui imports
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import NumberFormat from "react-number-format";
import Paper from "@mui/material/Paper";

import MessageList from "../messages/message-list";
import MessageForm from "../messages/message-form";

//import formatStage
import formatStage from "../../helpers/format-stage";
import TripHeader from "./trip-header";
import PaymentModule from "./payment-module";

import CancelTripModule from "./cancel-trip-module";

const toastOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "fieldChanged": {
      return {
        ...state,
        [action.field]: action.value,
      };
    }

    case "dataBeganDownloading": {
      return {
        ...state,
        loading: true,
      };
    }
    case "dataDownloaded": {
      return {
        ...state,
        data: action?.payload?.data,
        loading: false,
        dataDownloadedTimestamp: Date.now(),
        operatorFinancialRecords:
          action?.payload?.data?.operatorFinancialRecords,
      };
    }
    case "rowSelectionEvent": {
      let selectedRows = [...state[action.selectionKey]];
      let records = [...state[action.recordKey]];

      if (action.selected) {
        //get the row from the records by rowId
        let row = records.filter((record) => record._id === action.rowId);

        //add the row to the selectedRows
        selectedRows.push(row[0]);
      } else {
        //remove the row from the selectedRows
        selectedRows = selectedRows.filter(
          (selectedRow) => selectedRow._id !== action.rowId
        );
      }

      return {
        ...state,
        [action.selectionKey]: selectedRows,
      };
    }

    case "closeOFRModal": {
      return {
        ...state,
        ofrModalControls: {
          ...state.ofrModalControls,
          showModal: false,
        },
      };
    }

    default:
      break;
  }
  return state;
};

const SingleTripContent = (props) => {
  const { id } = useParams();
  const history = useHistory();

  const { getAccessTokenSilently, user, loginWithRedirect } = useAuth0();

  const userRoles = user && user["https://app.evojets.com/roles"];

  const superAdmin = userRoles.some(function (role) {
    return "Super Admin" === role;
  });

  const initialState = {
    loading: true,
    data: {
      tripNotes: [],
      catering: [],
      groundTransportationNotes: [],
    },
    refreshDataToken: Date.now(),
    dataDownloadedTimestamp: Date.now(),
    operatorFinancialRecords: [],
    operatorFinancialRecordsColumns: [
      {
        label: "Last Updated",
        field: "createdAt",
        sort: "asc",
        format: "date",
        placement: "standard",
      },
      {
        label: "Operator Name",
        field: "operator.name",
        sort: "asc",
        format: null,
      },
      {
        label: "Aircraft",
        field: "aircraft.name",
        sort: "asc",
        format: null,
        placement: "standard",
      },
      {
        label: "Operator Price",
        field: "operatorPrice",
        sort: "asc",
        format: "currency",
        placement: "standard",
      },
      {
        label: "Total Charges",
        field: "totalClientPayment",
        sort: "asc",
        format: "currency",
        placement: "standard",
      },
      {
        label: "Stage",
        field: "stage",
        sort: "asc",
        format: "stage",
        placement: "standard",
      },
      // {
      //   label: "Selected",
      //   type: "selection",
      // },
    ],
    operatorFinancialRecordsAccordionChildren: [
      {
        label: "Actions",
        render: (row, context, handleActionButton) => {
          return (
            <React.Fragment key={`key-row-${row._id}-link`}>
              <div className="d-flex flex-wrap flex-align-center gap-20">
                <Link
                  to={`/trips/${id}/financials/${row._id}`}
                  className="base-button evo-blue"
                  key={`key-row-${row._id}-link`}
                >
                  Edit OFR
                </Link>

                {row.stage === "saved" && (
                  <button
                    className="base-button evo-dark-green "
                    onClick={handleActionButton}
                    name="requestSignature"
                    data-ofrid={row._id}
                  >
                    Request Signature
                  </button>
                )}
                {/* gt/.
                {row.stage === "inReview" && (
                  <button
                    className="base-button evo-dark-green "
                    onClick={handleActionButton}
                    name="markSent"
                    data-ofrid={row._id}
                  >
                    Mark Sent
                  </button>
                )} */}

                {row.stage === "inReview" && superAdmin && (
                  <button
                    className="base-button evo-dark-green "
                    onClick={handleActionButton}
                    name="markSigned"
                    data-ofrid={row._id}
                  >
                    Mark Signed
                  </button>
                )}

                {row.stage === "signed" && (
                  <button
                    className="base-button evo-dark-green "
                    onClick={handleActionButton}
                    name="cancelNoRefund"
                    data-ofrid={row._id}
                  >
                    Cancel - No Refund
                  </button>
                )}

                {row.stage === "signed" && (
                  <button
                    className="base-button evo-dark-green "
                    onClick={handleActionButton}
                    name="cancelYesRefund"
                    data-ofrid={row._id}
                  >
                    Cancel - Yes Refund
                  </button>
                )}

                {row.stage === "inReview" && (
                  <button
                    className="base-button evo-dark-green "
                    onClick={handleActionButton}
                    name="revokeSendRequest"
                    data-ofrid={row._id}
                  >
                    Revoke Send Request
                  </button>
                )}
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    evoContractsColumns: [
      {
        label: "Last Saved",
        field: "updatedAt",
        sort: "asc",
        format: "date",
        placement: "standard",
      },
      {
        label: "First Leg",
        sort: "asc",
        format: null,
        render: (row) => {
          return `${row?.legs[0]?.departureAirport.code} - ${row?.legs[0]?.arrivalAirport.code}`;
        },
        placement: "standard",
      },
      {
        label: "Last Leg",
        sort: "asc",
        format: null,
        render: (row) => {
          if (row?.legs?.length <= 1) return "-";

          return `${
            row?.legs[row?.legs?.length - 1]?.departureAirport.code
          } - ${row?.legs[row?.legs?.length - 1]?.arrivalAirport.code}`;
        },
        placement: "standard",
      },
      {
        label: "Aircraft",
        sort: "asc",
        format: null,
        render: (row) => {
          return row?.legs[0]?.aircraft?.name;
        },
        placement: "standard",
      },
      {
        label: "Amount",
        sort: "asc",
        format: "currency",
        render: (row) => {
          return row?.pricing?.flightCharges;
        },
        placement: "standard",
      },
      {
        label: "Stage",
        field: "stage",
        sort: "asc",
        format: "stage",
        placement: "standard",
      },
    ],
    evoContractsAccordionChildren: [
      {
        label: "Actions",
        placement: "accordion",
        render: (row) => {
          console.log("CONTRACT STAGE", row.stage);
          return (
            <Fragment key={`key-row-${row._id}-link`}>
              <div className="d-flex flex-wrap flex-align-center column-gap-20">
                <Link
                  to={`/trips/${row.tripId}/contracts/${row._id}/preview`}
                  className="base-button evo-blue"
                >
                  Preview Contract
                </Link>
                {row.stage !== "signed" && (
                  <Link
                    to={`/trips/${id}/contracts/${row._id}`}
                    className="base-button evo-blue "
                    key={`key-row-${row._id}-link`}
                  >
                    Edit Contract
                  </Link>
                )}
              </div>
            </Fragment>
          );
        },
      },
    ],
    evoInvoicesAccordionChildren: [
      {
        label: "Actions",
        placement: "accordion",
        render: (row) => {
          return (
            <Fragment key={`key-row-${row._id}-link`}>
              <div className="d-flex flex-wrap flex-align-center column-gap-20">
                <Link
                  to={`/trips/${id}/invoices/${row._id}/preview`}
                  className="base-button evo-blue"
                >
                  Preview Invoice
                </Link>
                <Link
                  to={`/trips/${id}/invoices/${row._id}`}
                  className="base-button evo-blue "
                  key={`key-row-${row._id}-link`}
                >
                  Edit Invoice
                </Link>
                <button
                  onClick={() => handleDeleteInvoice(row._id)}
                  className="base-button btn-danger"
                  key={`key-row-${row._id}-link`}
                >
                  Delete Invoice
                </button>
              </div>
            </Fragment>
          );
        },
      },
    ],
    invoiceRecords: [],
    invoiceColumns: [
      {
        label: "Aircraft",
        field: "aircraft",
        sort: "asc",
        format: "string",
        placement: "standard",
      },
      {
        label: "Total Charges",
        field: "totalCharges",
        sort: "asc",
        format: "string",
        placement: "standard",
      },
      {
        label: "Total Payments",
        field: "totalPayments",
        sort: "asc",
        format: "string",
        placement: "standard",
      },
      {
        label: "Created By",
        field: "createdBy",
        sort: "asc",
        format: "string",
        placement: "standard",
      },
    ],
    itineraryColumns: [
      {
        label: "Actions",
        placement: "standard",
        render: (row, context) => {
          if (
            context.operatorFinancialRecords?.length &&
            context.itineraryData?.length
          ) {
            //has ofr and itin
            return (
              <Link to={`/trips/${id}/itineraries/${row._id}/preview`}>
                Preview Itinerary
              </Link>
            );
          }
        },
      },
      {
        label: "Last Saved",
        field: "updatedAt",
        sort: "asc",
        format: "date",
        placement: "standard",
      },
      {
        label: "First Leg",
        sort: "asc",
        format: null,
        render: (row) => {
          return `${row?.legs[0]?.departureAirport.code} - ${row?.legs[0]?.arrivalAirport.code}`;
        },
        placement: "standard",
      },
      {
        label: "Last Leg",
        sort: "asc",
        format: null,
        render: (row) => {
          if (row?.legs?.length <= 1) return "-";

          return row?.legs[row?.legs?.length - 1]?.arrivalAirport.airportName;
        },
        placement: "standard",
      },
      {
        label: "Operator",
        sort: "asc",
        format: null,
        render: (row) => {
          return row?.legs[0]?.operator?.name;
        },
        placement: "standard",
      },
      {
        label: "Aircraft",
        sort: "asc",
        format: null,
        render: (row) => {
          return row?.legs[0]?.aircraft?.name;
        },
        placement: "standard",
      },
      {
        label: "Stage",
        field: "stage",
        sort: "asc",
        format: "stage",
        placement: "standard",
      },
    ],
    itineraryTableFooter: [
      {
        render: (context) => {
          if (
            context.operatorFinancialRecords?.length &&
            !context.itineraryData?.length
          ) {
            // no itin, but has ofr
            return (
              <Link
                to={`/trips/${id}/itineraries/new`}
                className="evo-blue base-button"
              >
                Create Itinerary
              </Link>
            );
          } else if (!context.operatorFinancialRecords?.length) {
            //no ofr
            console.log("NO OFR", context.operatorFinancialRecords.length);
            return (
              <Link
                className="evo-blue base-button"
                to={`/trips/${id}/financials/new`}
              >
                Create OFR
              </Link>
            );
          }
        },
      },
    ],
    itineraryAccordionChildren: [
      {
        label: "Actions",
        render: (row, context) => {
          return (
            <Link
              to={`/trips/${id}/itineraries/${row._id}`}
              className="base-button evo-blue mt-20"
              key={`key-row-${row._id}-link`}
            >
              Edit Itinerary
            </Link>
          );
        },
      },
    ],
    quoteColumns: [
      {
        label: "Created At",
        field: "createdAt",
        sort: "asc",
        format: "date",
        placement: "standard",
      },
      {
        label: "Sent",
        sort: "asc",
        render: (row, context) => {
          // check if row.liveQuoteLog has a length
          //then check row.liveQuoteLog - last index, and get that index's timestamp
          // then render a timestamp in the following format
          if (row.liveQuoteLog?.length) {
            const lastTimestamp = row?.liveQuoteLog.at(-1)?.timestamp;

            if (lastTimestamp) {
              return moment(lastTimestamp).format("MMM Do YYYY h:mm a");
            }
          }
        },
        format: null,
      },
      {
        label: "Routing",
        sort: "asc",
        render: (row, context) => {
          if (row.legs?.length) {
            let routingString = ``;

            row.legs.map((item, index) => {
              routingString += `${index >= 1 ? ` | ` : ``}${
                item?.departureAirport?.code
              } - ${item?.arrivalAirport?.code}`;
            });

            return routingString;
          }
        },
        placement: "standard",
      },
    ],
    quoteAccordionChildren: [
      {
        label: "Actions",
        render: (row, context) => {
          return row?.aircraftOptions?.map((item, index) => {
            return (
              <div key={`${row._id}-quote-aircraftoptions-${index}`}>
                <p>
                  <strong>{item?.aircraft?.name}</strong> - {item?.totalCost}
                </p>
                <Link
                  to={`/trips/${id}/itineraries/${row._id}`}
                  className="base-button evo-blue mt-20"
                  key={`key-row-${row._id}-link`}
                  disabled
                >
                  Create Contract - COMING SOON
                </Link>
              </div>
            );
          });
        },
      },
    ],
    selectedOFRs: [],
    ofrRequestData: {
      notes: "",
    },
    ofrModalControls: {
      showModal: false,
      modalTitle: "",
      buttonText: "Submit",
      showContractSelector: false,
    },
  };

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const [state, dispatch] = useReducer(reducer, initialState);

  const formatCurrency = (x) => {
    return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getData = async () => {
    dispatch({ type: "dataBeganDownloading" });

    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let response = await ai
      .auth(token)
      .get(`/api/trips/${id}`)
      .catch((error) => {
        // history.push({ pathname: "/trips" });
        setTimeout(() => {
          toast.error("Unauthorized!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }, 50);

        return console.error(error.response);
      });

    console.log("TRIP RESP", response?.data);

    if (response?.data && response?.data?.invoiceData?.length) {
      const invoicesForThisTrip = response?.data?.invoiceData.map((invoice) => {
        return {
          ...invoice,
          aircraft: invoice?.invoiceDetails[0]?.description || "N/A",
          totalCharges: currencyFormatter.format(
            invoice?.invoiceDetails.reduce(
              (acc, charge) => acc + charge.amount,
              0
            )
          ),
          createdBy: invoice?.createdBy.name,
          totalPayments: currencyFormatter.format(
            invoice?.paymentDetails.reduce(
              (acc, payment) => acc + payment.amount,
              0
            )
          ),
        };
      });
      dispatch({
        type: "fieldChanged",
        field: "invoiceRecords",
        value: invoicesForThisTrip,
      });
    }

    if (response) {
      dispatch({
        type: "dataDownloaded",
        payload: {
          data: response.data,
        },
      });
    }
  };

  const handleTextInputChange = (e) => {
    let value = e.target.value;

    dispatch({
      type: "fieldChanged",
      field: e.target.name,
      value: value,
    });
  };
  const handleDeleteInvoice = async (id) => {
    const token = await getAccessTokenSilently();
    await ai.auth(token).delete(`/api/invoices/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    getData("", true);
  };

  const handleRootTextInputChange = (e) => {
    let value = e.target.value;

    dispatch({
      type: "rootFieldChanged",
      field: e.target.name,
      value: value,
    });
  };

  useEffect(() => {
    getData();
    document.title = "Trip | Flight Deck by evoJets";
  }, []);

  useEffect(() => {
    getData();
  }, [state.refreshDataToken]);

  const handleOFRActionButton = async (event) => {
    event.preventDefault();

    console.log("OFR ACTION BUTTON", state);

    dispatch({
      type: "fieldChanged",
      field: "action",
      value: event.target.name,
    });

    let modalResponse = handleOFRModal(event);

    if (modalResponse?.requiresModal) {
      return;
    }

    const response = await handleOFRActionSubmit(
      event.target.name,
      modalResponse?.ofrRequestData
    );

    return response;
  };

  const handleOFRModal = (event) => {
    let action = event?.target?.name;
    let ofrId = event?.target?.dataset?.ofrid;

    let actionsRequiringModal = [
      "requestSignature",
      "cancelNoRefund",
      "cancelYesRefund",
    ];

    let ofrModalControls = {
      ...state.ofrModalControls,
      ofrModalButtonText: "Submit",
      showContractSelector: false,
    };

    let ofrRequestData = {
      ...state.ofrRequestData,
    };

    //get the ofrRecord from operatorFinancialRecords by ofrId
    let ofr = state?.operatorFinancialRecords?.filter(
      (ofr) => ofr._id === event?.target?.dataset?.ofrid
    )[0];

    //if only one contract, set contractData to ofrRequestData
    let contractData = {};
    if (state.data?.contractData?.length === 1) {
      contractData = { ...state.data?.contractData[0] };
    }

    if (state.data?.contractData?.length > 1) {
      if (ofr?.associatedContractId) {
        //ofr already has associated contract
        contractData = {
          ...state.data?.contractData?.filter(
            (contract) => contract._id === ofr?.associatedContractId
          )[0],
        };
      }

      if (!ofr?.associatedContractId) {
        //ofr has no associated contract
        //show contract Selector

        ofrModalControls = {
          ...ofrModalControls,
          showContractSelector: true,
        };
      }
    }

    ofrRequestData = {
      ...ofrRequestData,
      notes: "",
      ofrId: ofrId,
      contract: {
        id: contractData._id,
        client: {
          firstName: contractData?.clientData?.firstName,
          lastName: contractData?.clientData?.lastName,
          isBusiness: !!contractData?.clientData?.isBusiness,
          companyName: contractData?.clientData?.companyName,
          id: contractData?.clientData?._id,
        },
      },
      tripTag: state.data?.tripTag,
    };

    if (!actionsRequiringModal.includes(action) || !action || !ofrId) {
      ofrModalControls = {
        ...ofrModalControls,
        ofr: ofr,
        associatedContractData: contractData,
        action: action,
        showModal: false,
      };

      dispatch({
        type: "fieldChanged",
        field: "ofrModalControls",
        value: ofrModalControls,
      });

      dispatch({
        type: "fieldChanged",
        field: "ofrRequestData",
        value: ofrRequestData,
      });

      return {
        requiresModal: false,
        ofrModalControls: ofrModalControls,
        ofrRequestData: ofrRequestData,
      };
    }

    if (action === "requestSignature") {
      //show requestSignature

      ofrModalControls = {
        ...ofrModalControls,
        modalTitle: "Request OFR Signature",
        action: action,
        associatedContractData: contractData,
        ofr: ofr,
        showModal: true,
      };
    }

    if (action === "cancelNoRefund" || action === "cancelYesRefund") {
      //show requestSignature

      ofrModalControls = {
        ...ofrModalControls,
        modalTitle: `Cancel OFR - ${
          action === "cancelNoRefund" ? "Without" : "With"
        } Refund`,
        action: action,
        associatedContractData: contractData,
        ofr: ofr,
        showModal: true,
      };
    }

    console.log("OFR MODAL CONTROLS", ofrModalControls);
    console.log("ASSOCIATED CONTRACT ID", ofr?.associatedContractId);
    dispatch({
      type: "fieldChanged",
      field: "ofrModalControls",
      value: ofrModalControls,
    });

    dispatch({
      type: "fieldChanged",
      field: "ofrRequestData",
      value: ofrRequestData,
    });

    return {
      requiresModal: true,
      ofrModalControls: ofrModalControls,
      ofrRequestData: ofrRequestData,
    };
  };

  const handleModalSelectChange = (selectedValue, action) => {
    console.log({ selectedValue, action });

    dispatch({
      type: "modalFieldChanged",
      field: action.name,
      value: selectedValue.value,
    });
  };

  const handleOFRModalFormSubmission = async (e) => {
    e.preventDefault();

    await handleOFRActionSubmit(state.action);

    return true;
  };

  const handleContractSelectorChange = (selectedContract, action) => {
    console.log({ selectedContract, action });

    let contractData = { ...selectedContract?.contractData };

    dispatch({
      type: "fieldChanged",
      field: "ofrRequestData",
      value: {
        ...state.ofrRequestData,
        contract: {
          id: contractData._id,
          client: {
            firstName: contractData?.clientData?.firstName,
            lastName: contractData?.clientData?.lastName,
            isBusiness: !!contractData?.clientData?.isBusiness,
            companyName: contractData?.clientData?.companyName,
            id: contractData?.clientData?._id,
          },
        },
      },
    });

    dispatch({
      type: "fieldChanged",
      field: "ofrModalControls",
      value: {
        ...state.ofrModalControls,
        associatedContractData: contractData,
      },
    });
  };

  // useEffect(() => {
  //   let showRequestSignature = false;
  //   if (state.selectedOFRs.length) {
  //     state.selectedOFRs.map((ofr) => {
  //       if (ofr.stage === "saved") {
  //         showRequestSignature = true;
  //       }
  //     });
  //   }
  //   dispatch({
  //     type: "fieldChanged",
  //     field: "showRequestSignature",
  //     value: showRequestSignature,
  //   });
  // }, [state.selectedOFRs]);

  const handleOFRActionSubmit = async (action, ofrRequestData) => {
    const token = await getAccessTokenSilently().catch((e) => {
      console.error(e);
      return loginWithRedirect();
    });

    let actionData = state?.ofrRequestData;
    let ofrId = state?.ofrRequestData?.ofrId;

    if (ofrRequestData) {
      actionData = ofrRequestData;
      ofrId = ofrRequestData?.ofrId;
    }

    console.log("ACTION SUBMIT", {
      action: action,
      actionData: ofrRequestData,
      id: ofrId,
    });

    let response = await ai
      .auth(token)
      .put(`/api/trips/${id}/financials/${ofrId}/action`, {
        action: action,
        actionData: actionData,
      })
      .catch((error) => {
        setTimeout(() => {
          toast.error("Unauthorized!", toastOptions);
        }, 50);

        return console.error(error.response);
      });

    if (response.data && response?.data?.message) {
      toast.success(response?.data?.message, toastOptions);
      dispatch({
        type: "fieldChanged",
        field: "ofrModalControls",
        value: {
          ...state.ofrModalControls,
          showModal: false,
        },
      });
    }

    console.log(response.data);
  };

  const resetSelection = (fieldName) => {
    setTimeout(() => {
      dispatch({
        type: "modalFieldChanged",
        field: fieldName,
        value: null,
      });
    }, 0);
  };

  return (
    <Fragment>
      {!state.loading && (
        <TripHeader
          breadcrumbs={[{ link: "/trips", label: "Trips" }]}
          data={state.data?.headerData}
        ></TripHeader>
      )}

      {state.loading && <Loader></Loader>}

      {!state.loading && (
        <Fragment>
          <h3 className="mt-20 mb-10">ITINERARY DATA</h3>

          {state?.data?.itineraryData && !state.loading && (
            <DynamicAccordionTable
              rows={state.data.itineraryData}
              columns={state.itineraryColumns}
              context={state.data}
              tableClassName="mt-0"
              tableFooter={state.itineraryTableFooter}
              accordionChildren={state.itineraryAccordionChildren}
              key={`itinerary-table`}
              tableName={`itinerary-table`}
            ></DynamicAccordionTable>
          )}

          <hr className="mt-30"></hr>

          <h3 className="mt-20 mb-0">Operator Financial Records</h3>

          <DynamicAccordionTable
            rows={state.operatorFinancialRecords}
            columns={state.operatorFinancialRecordsColumns}
            tableClassName="mt-10"
            context={state.data}
            accordionChildren={state.operatorFinancialRecordsAccordionChildren}
            key={`ofr-table`}
            tableName={`ofr-table`}
            parentDispatch={dispatch}
            handleActionButton={handleOFRActionButton}
          ></DynamicAccordionTable>

          <Link
            className="base-button evo-blue d-inline-block  mt-30"
            to={{ pathname: `/trips/${id}/financials/new`, state: state.data }}
          >
            Create OFR
          </Link>

          <hr className="mt-30"></hr>
          <h3>EVO CONTRACTS</h3>

          {state?.data?.contractData && (
            <DynamicAccordionTable
              rows={state.data.contractData}
              columns={state.evoContractsColumns}
              tableClassName="mt-10"
              context={state.data}
              accordionChildren={state.evoContractsAccordionChildren}
              key={`contracts-table`}
              tableName={`contracts-table`}
            ></DynamicAccordionTable>
          )}
          <Link
            className="base-button evo-blue d-inline-block  mt-30"
            to={{ pathname: `/trips/${id}/contracts/new`, state: state.data }}
          >
            Create Contract
          </Link>

          <>
            <hr className="mt-30"></hr>
            <h3>INVOICES</h3>
            <DynamicAccordionTable
              rows={state.invoiceRecords}
              columns={state.invoiceColumns}
              tableClassName="mt-10"
              context={state.data}
              accordionChildren={state.evoInvoicesAccordionChildren}
              key={`ofr-table`}
              tableName={`ofr-table`}
              parentDispatch={dispatch}
              handleActionButton={handleOFRActionButton}
            ></DynamicAccordionTable>
            <Link
              className="base-button evo-blue d-inline-block  mt-30"
              to={{
                pathname: `/trips/${id}/invoices/new`,
                state: { tripId: id, data: state.data },
              }}
            >
              Create Invoice
            </Link>
          </>

          {!state.loading && (
            <Paper className="messages-container mt-30 pb-1">
              <h4 className="lead-notes-heading">Trip Messages</h4>

              <MessageList
                threadId={id}
                latestMessage={state.latestMessage}
                parentDispatch={dispatch}
                recordType="trip"
              ></MessageList>
              <MessageForm
                recordId={id}
                recordType="trip"
                parentDispatch={dispatch}
              ></MessageForm>
            </Paper>
          )}

          <hr className="mt-30"></hr>
          <h3>QUOTE DATA</h3>

          {state?.data?.quotes && !state.loading && (
            <DynamicAccordionTable
              rows={state.data.quotes || []}
              columns={state.quoteColumns}
              tableClassName="mt-10"
              context={state.data}
              accordionChildren={state.quoteAccordionChildren}
              key={`quotes-table`}
              tableName={`quotes-table`}
            ></DynamicAccordionTable>
          )}

          <hr className="mt-30"></hr>

          <PaymentModule
            tripId={id}
            dataDownloadedTimestamp={state.dataDownloadedTimestamp}
            parentDispatch={dispatch}
            records={state?.data?.clientPaymentSchedule || []}
            columns={[
              {
                label: "Due Date",
                key: "paymentDate",
              },
              {
                label: "Amount",
                key: "amount",
              },
              {
                label: "Method",
                key: "paymentType",
              },
            ]}
            recordType={`clientPaymentSchedule`}
            title={`Client Payment Schedule`}
            selectable
          ></PaymentModule>

          <PaymentModule
            tripId={id}
            dataDownloadedTimestamp={state.dataDownloadedTimestamp}
            parentDispatch={dispatch}
            records={state?.data?.clientPaymentHistory || []}
            columns={[
              {
                label: "Due Date",
                key: "paymentDate",
              },
              {
                label: "Amount",
                key: "amount",
              },
              {
                label: "Payment Type",
                key: "paymentType",
              },
              {
                label: "Entered By",
                key: "enteredBy",
              },
              {
                label: "Notes",
                key: "notes",
              },
            ]}
            recordType={`clientPaymentHistory`}
            title={`Client Payment History`}
            selectable
          ></PaymentModule>

          <hr className="mt-30"></hr>

          <div className="two-column-grid">
            {state?.data && (
              <TripNotesModule
                tripId={id}
                // dataDownloadedTimestamp={state.dataDownloadedTimestamp}
                parentDispatch={dispatch}
                tripNotes={state?.data?.tripNotes || []}
                key={`key-trips-data-${state.dataDownloadedTimestamp.toString()}`}
              ></TripNotesModule>
            )}

            {state.data && (
              <GroundTransportationModule
                tripId={id}
                dataDownloadedTimestamp={state.dataDownloadedTimestamp}
                parentDispatch={dispatch}
                groundTransportationNotes={
                  state?.data?.groundTransportation || []
                }
                key={`key-trips-${state.dataDownloadedTimestamp.toString()}`}
              ></GroundTransportationModule>
            )}

            {state.data && (
              <CateringModule
                tripId={id}
                dataDownloadedTimestamp={state.dataDownloadedTimestamp}
                parentDispatch={dispatch}
                catering={state?.data?.catering || []}
                key={`key-catering-${state.dataDownloadedTimestamp.toString()}`}
              ></CateringModule>
            )}
          </div>

          <ReactModal
            isOpen={state?.ofrModalControls?.showModal}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => {
              dispatch({
                type: "closeOFRModal",
              });
            }}
            style={{
              overlay: {
                zIndex: 999999,
                backgroundColor: "rgb(32 53 68 / 50%)",
              },
              content: {
                width: "auto",
                // height: "700px",
                // padding: "40px",
                border: "none",
                // maxWidth: "700px",
                overflowY: "scroll",
                maxHeight: "100vh",
                minWidth: "50vw",
                borderRadius: "0",
                boxShadow: "10px 10px 91px -17px rgb(0 0 0 / 25%)",
              },
            }}
            appElement={document.getElementById("app")}
          >
            <div className="modal-header edit-note-modal">
              <h4>{state?.ofrModalControls?.modalTitle}</h4>
            </div>
            <div className="modal-body edit-note-modal overflow-scroll">
              <form
                onSubmit={handleOFRModalFormSubmission}
                className="w-100 base-form mt-20 tight-paragraphs"
              >
                {state?.ofrModalControls?.ofr && (
                  <div>
                    <h5>OFR #{state?.ofrModalControls?.ofr?.ofrTag}</h5>
                    <ul>
                      <li>
                        <strong>Operator Name:</strong>{" "}
                        {state?.ofrModalControls?.ofr?.operator?.name}
                      </li>
                      <li>
                        <strong>Operator Price:</strong>{" "}
                        {currencyFormatter.format(
                          state?.ofrModalControls?.ofr?.operatorPrice
                        )}
                      </li>
                    </ul>

                    {state?.ofrModalControls?.showContractSelector && (
                      <div className="form-group">
                        <label>Associated Contract</label>
                        <Select
                          className="base-select yom-select"
                          isClearable
                          isSearchable
                          placeholder={"Select a Contract"}
                          name="associatedContract"
                          onChange={handleContractSelectorChange}
                          options={state?.data?.contractData?.map(
                            (contract) => {
                              //depart airport code of each leg, separated by -

                              let routing = contract?.legs?.map(
                                (leg) => leg?.departureAirport?.code
                              );

                              routing = routing.join("-");

                              //updated at, eastern time, like June 1, 2023 2:00 PM

                              let updatedAt = moment(
                                contract?.updatedAt
                              ).format("MMM D, YYYY h:mm A");

                              let stage = formatStage(contract?.stage);

                              return {
                                label: `#${contract.contractTag} | ${contract.clientData.firstName} ${contract.clientData.lastName} | ${routing} | Updated: ${updatedAt} | ${stage}`,
                                value: contract._id,
                                contractData: contract,
                              };
                            }
                          )}
                        ></Select>
                      </div>
                    )}

                    <h6>Trip & Contract Data</h6>

                    <ul>
                      <li>
                        <strong>Trip ID: #</strong>
                        {state.ofrRequestData?.tripTag}
                      </li>
                      <li>
                        <strong>Contract ID: </strong>
                        {state.ofrModalControls?.associatedContractData
                          ?.contractTag
                          ? `#${state.ofrModalControls?.associatedContractData?.contractTag}`
                          : "Select an associated contract."}
                      </li>

                      <li>
                        <strong>Client: </strong>

                        {!state.ofrModalControls?.associatedContractData._id &&
                          `Select an associated contract.`}

                        {state.ofrModalControls?.associatedContractData?._id &&
                          state.ofrRequestData?.contract?.client?.isBusiness &&
                          `${state.ofrRequestData?.contract?.client?.companyName}`}
                        {state.ofrModalControls?.associatedContractData?._id &&
                          !state.ofrRequestData?.contract?.client?.isBusiness &&
                          `${state.ofrRequestData?.contract?.client?.firstName} ${state.ofrRequestData?.contract?.client?.lastName}`}
                      </li>

                      <li>
                        <strong>Trip Legs: </strong>{" "}
                        {state.ofrModalControls?.ofr?.whichLeg === 1
                          ? "All"
                          : state.ofrModalControls?.ofr?.whichLeg === 2
                          ? "Partial"
                          : "Please define this on the OFR record before requesting a signature."}
                      </li>
                    </ul>

                    <h6>Cancellation Policy</h6>
                    <ul>
                      {!!state.ofrModalControls?.ofr
                        ?.operatorCancellationPolicies?.length &&
                        state.ofrModalControls?.ofr?.operatorCancellationPolicies.map(
                          (penalty) => {
                            return (
                              <li key={penalty._id}>
                                <strong>
                                  {penalty.type === 1 && `${penalty.value}%`}

                                  {penalty.type === 2 &&
                                    currencyFormatter.format(penalty.value)}
                                </strong>{" "}
                                on{" "}
                                {moment(penalty.dueDate).format("MMM D, YYYY")}{" "}
                                <em>
                                  {`(`}
                                  {moment(penalty.dueDate).fromNow()}
                                  {`)`}
                                </em>
                              </li>
                            );
                          }
                        )}
                    </ul>
                  </div>
                )}

                {(state.ofrModalControls?.action === "cancelYesRefund" ||
                  state.ofrModalControls?.action === "cancelNoRefund") && (
                  <div className="form-group">
                    <label>Cancellation Reason</label>
                    <Select
                      className="base-select yom-select"
                      isClearable
                      isSearchable
                      placeholder={"Select a Reason"}
                      name="cancellationReason"
                      onChange={(selection) => {
                        dispatch({
                          type: "fieldChanged",
                          field: "ofrModalControls",
                          value: {
                            ...state.ofrModalControls,
                            cancellationReason: selection,
                          },
                        });

                        dispatch({
                          type: "fieldChanged",
                          field: "ofrRequestData",
                          value: {
                            ...state.ofrRequestData,
                            cancellationReason: selection.value,
                          },
                        });
                      }}
                      options={[
                        {
                          label: "Client - Changed Trip",
                          value: "Client - Changed Trip",
                          notesRequired: true,
                        },
                        {
                          label: "Client - Canceled Part of Trip",
                          value: "Client - Canceled Part of Trip",
                          notesRequired: true,
                        },
                        {
                          label: "Owner - Mechanical/Crew/Ops",
                          value: "Owner - Mechanical/Crew/Ops",
                          notesRequired: false,
                        },
                        {
                          label: "Owner - Plane No Longer Available",
                          value: "Owner - Plane No Longer Available",
                          notesRequired: false,
                        },
                        {
                          label: "Owner - Pulled Plane (after Evo booked it)",
                          value: "Owner - Pulled Plane (after Evo booked it)",
                          notesRequired: false,
                        },
                        {
                          label: "Owner - Not Approved",
                          value: "Owner - Not Approved",
                          notesRequired: false,
                        },
                        {
                          label: "Owner - Other",
                          value: "Owner - Other",
                          notesRequired: true,
                        },
                        {
                          label: "Evo - Found Better Aircraft",
                          value: "Evo - Found Better Aircraft",
                          notesRequired: false,
                        },
                        {
                          label: "Evo - Client Ghost/Non-Compliant",
                          value: "Evo - Client Ghost/Non-Compliant",
                          notesRequired: true,
                        },
                        {
                          label: "Weather",
                          value: "Weather",
                          notesRequired: false,
                        },
                        {
                          label: "Other Logistical",
                          value: "Other Logistical",
                          notesRequired: true,
                        },
                      ]}
                    ></Select>
                  </div>
                )}

                {(state.ofrModalControls?.action === "cancelYesRefund" ||
                  state.ofrModalControls?.action === "cancelNoRefund") &&
                  state.ofrModalControls?.cancellationReason?.notesRequired && (
                    <div className="form-group">
                      <label className="base-label" htmlFor="note">
                        Cancellation Reason Notes
                      </label>
                      <textarea
                        name="cancellationReasonNotes"
                        id="cancellationReasonNotes"
                        cols="30"
                        rows="3"
                        value={
                          state?.ofrRequestData?.cancellationReasonNotes || ""
                        }
                        onChange={(e) => {
                          dispatch({
                            type: "fieldChanged",
                            field: "ofrRequestData",
                            value: {
                              ...state.ofrRequestData,
                              cancellationReasonNotes: e.target.value,
                            },
                          });
                        }}
                        required
                        placeholder={`Please clarify the situation.`}
                      ></textarea>
                    </div>
                  )}

                {state.ofrModalControls?.action === "cancelYesRefund" && (
                  <React.Fragment>
                    <FormControl className="form-group base-radio-group">
                      <label id="refund-label">Refund Details</label>

                      <RadioGroup
                        aria-labelledby="refund-label"
                        name="refundDetails"
                        value={state.ofrRequestData?.refundDetails}
                        onChange={(e) => {
                          dispatch({
                            type: "fieldChanged",
                            field: "ofrRequestData",
                            value: {
                              ...state.ofrRequestData,
                              refundDetails: e.target.value,
                            },
                          });
                        }}
                      >
                        <FormControlLabel
                          value="Full"
                          control={<Radio />}
                          label="Full"
                        />
                        <FormControlLabel
                          value="Partial"
                          control={<Radio />}
                          label="Partial"
                        />
                        <FormControlLabel
                          value="TBD"
                          control={<Radio />}
                          label="TBD"
                        />
                        <FormControlLabel
                          value="Credit"
                          control={<Radio />}
                          label="Credit"
                        />
                      </RadioGroup>
                    </FormControl>

                    {state.ofrRequestData?.refundDetails &&
                      ["Partial", "TBD", "Credit"].includes(
                        state.ofrRequestData.refundDetails
                      ) && (
                        <div className="form-group">
                          <label className="base-label" htmlFor="note">
                            Operator Refund Notes
                          </label>
                          <textarea
                            name="operatorRefundNotes"
                            id="operatorRefundNotes"
                            cols="30"
                            rows="3"
                            value={
                              state?.ofrRequestData?.operatorRefundNotes || ""
                            }
                            onChange={(e) => {
                              dispatch({
                                type: "fieldChanged",
                                field: "ofrRequestData",
                                value: {
                                  ...state.ofrRequestData,
                                  operatorRefundNotes: e.target.value,
                                },
                              });
                            }}
                            required
                            placeholder={`Please clarify the refund details.`}
                          ></textarea>
                        </div>
                      )}
                  </React.Fragment>
                )}

                {(state.ofrModalControls?.action === "requestSignature" ||
                  state.ofrModalControls?.action === "cancelNoRefund" ||
                  state.ofrModalControls?.action === "cancelYesRefund") &&
                  state.ofrModalControls?.ofr?.whichLeg === 2 && (
                    <React.Fragment>
                      <div className="form-group">
                        <label className="base-label" htmlFor="note">
                          Partial OFR Notes <span className="required">*</span>
                        </label>
                        <textarea
                          name="partialOFRNotes"
                          id="partialOFRNotes"
                          cols="30"
                          rows="3"
                          value={state?.ofrRequestData?.partialOFRNotes || ""}
                          onChange={(e) => {
                            dispatch({
                              type: "fieldChanged",
                              field: "ofrRequestData",
                              value: {
                                ...state.ofrRequestData,
                                partialOFRNotes: e.target.value,
                              },
                            });
                          }}
                          required={state.ofrModalControls?.ofr?.whichLeg === 2}
                          placeholder={
                            state.ofrModalControls?.ofr?.whichLeg === 2
                              ? "Please enter a reason for requesting a partial OFR."
                              : ""
                          }
                        ></textarea>
                      </div>
                    </React.Fragment>
                  )}

                {(state.ofrModalControls?.action === "requestSignature" ||
                  state.ofrModalControls?.action === "cancelNoRefund" ||
                  state.ofrModalControls?.action === "cancelYesRefund") && (
                  <React.Fragment>
                    <div className="form-group">
                      <label className="base-label" htmlFor="note">
                        Request Notes
                      </label>
                      <textarea
                        name="notes"
                        id="notes"
                        cols="30"
                        rows="3"
                        value={state?.ofrRequestData?.notes || ""}
                        onChange={(e) => {
                          dispatch({
                            type: "fieldChanged",
                            field: "ofrRequestData",
                            value: {
                              ...state.ofrRequestData,
                              notes: e.target.value,
                            },
                          });
                        }}
                        placeholder={`Additional request notes.`}
                      ></textarea>
                    </div>
                  </React.Fragment>
                )}

                {state.ofrModalControls?.ofr?.whichLeg && (
                  <button
                    className="base-button evo-blue w-100 mt-20"
                    type="submit"
                  >
                    {state.ofrModalControls?.modalButtonText
                      ? state.ofrModalControls?.modalButtonText
                      : "Submit"}
                  </button>
                )}

                {!state.ofrModalControls?.ofr?.whichLeg && (
                  <p>
                    <strong>
                      You must associate your OFR with legs before you can
                      submit.
                    </strong>
                  </p>
                )}
              </form>
            </div>
          </ReactModal>

          <CancelTripModule
            tripId={id}
            parentDispatch={dispatch}
            tripData={state?.data}
          ></CancelTripModule>

          <StateDisplay user={user} data={user}></StateDisplay>
          <StateDisplay user={user} data={state}></StateDisplay>
        </Fragment>
      )}
    </Fragment>
  );
};

export default SingleTripContent;
